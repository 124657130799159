import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/layout'
import Hero from '../components/hero'
import * as styles from './about.module.css'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

class About extends React.Component {
  render() {
    const [about] = get(this, 'props.data.allContentfulHomepage.nodes')

    return (
      <Layout location={this.props.location}>
        <Hero
          image={about.heroImage.gatsbyImage}
          title='About'
        />
        <div className={styles.content}>{renderRichText(about.content)}</div>
      </Layout>
    )
  }
}

export default About

export const pageQuery = graphql`
    query AboutQuery {
        allContentfulHomepage {
            nodes {
                content {
                    raw
                }
                heroImage: mainImage {
                    gatsbyImage(
                        layout: CONSTRAINED
                        placeholder: BLURRED
                        width: 1180
                    )
                }
            }
        }
    }
`
